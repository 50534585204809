import MobileDetect from 'mobile-detect'
import ClipboardJS from 'clipboard'
import qrcode from 'qrcode-generator'

const md = new MobileDetect(navigator.userAgent)
        
if (md.is('AndroidOS')) { // Android
    document.querySelector('.android').classList.remove('is-hidden')
} else if (md.is('iPhone') || md.is('iPad')) { // iOS
    document.querySelector('.ios-homescreen').classList.remove('is-hidden')
} else {
    document.querySelector('.unsupported').classList.remove('is-hidden')
}

// Clipboard
const clipboard = new ClipboardJS('[data-clipboard-text]')

clipboard.on('success', function(e) {
    e.trigger.innerHTML = '<span>&check; Copied</span>'
    e.trigger.style.backgroundColor = 'green'
    e.clearSelection()
})

// QR Code
const qr = qrcode(4, 'L')
qr.addData('https://commercescope.app')
qr.make()

document.getElementById('qr-code').innerHTML = qr.createSvgTag(6)